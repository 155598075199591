<template lang="pug">
  footer.footer
    div.container
      div.footer__content
        div.footer__nav
          a(href="/").footer__logo.logo.transition
            img(:src="Logo" alt="logo")
          nav.navigation
            ul
              li
                router-link(to="/privacy-policy") Privacy Policy
              li
                router-link(to="/terms&conditions") Terms of USE
              li.copyright © Copyright {{ new Date().getFullYear() }}, All Rights Reserved
</template>

<script>
// images
import Logo from '@/assets/icons/footer-logo.svg'
export default {
  name: 'FooterPiece',
  data () {
    return {
      Logo
    }
  }
}
</script>
